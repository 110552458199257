import $ from 'jquery';
import 'slick-carousel';

jQuery(function() {
    //  Grab Cursor when grabbing a Slide
    $(".slick-slide").on("mousedown touchstart", function() {
        $(this).addClass('grabbing');
    });

    $(".slick-slide").on("mouseup touchend", function() {
        $(this).removeClass('grabbing');
    });

    $('#slider-home-background').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        lazyLoad: 'ondemand',
        asNavFor: '#slider-home-img, #slider-home-text, #slider-home-img-shadow'
    });

    $('#slider-home-img').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        lazyLoad: 'ondemand',
        asNavFor: '#slider-home-background, #slider-home-text, #slider-home-img-shadow'
    });
    
    $('#slider-home-text').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: true,
        fade: true,
        asNavFor: '#slider-home-background, #slider-home-img, #slider-home-img-shadow'
    });

    $('#slider-home-img-shadow').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        fade: true,
        asNavFor: '#slider-home-background, #slider-home-img, #slider-home-text'
    });

    // Bloc Produits Tendances
    $('#slider-trend').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        lazyLoad: 'ondemand',
    });

    // Page Single Product
    $('#slider-product-show').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        asNavFor: '#slider-product-select'
    });
    $('#slider-product-select').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: true,
        focusOnSelect: true,
        asNavFor: '#slider-product-show'
    });

    // Bloc Produits Tendances
    $('.slider-simple').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        speed: 1000,
        infinite: true,
        adaptiveHeight: false,
        arrows: false,
        lazyLoad: 'ondemand',
    });
});