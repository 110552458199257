$(window).on("load", function() {
	$('.hamburger').on('click', function() {
		if($('body').hasClass('show-nav')) {
			$('body').removeClass('show-nav').addClass('hide-nav');
			setTimeout(function() {
				$('body').removeClass('hide-nav');
			}, 500);
		} else {
			$('body').removeClass('hide-nav').addClass('show-nav');
		}
		return false;
	});
});

window.addEventListener('resize', function() {
	addPaddingSubmenu();
});

function addPaddingSubmenu() {
	const menuTerms = document.querySelectorAll('.menu-term');
	for(let i = 0; i < menuTerms.length; i++) {
		let menuPaddingLeft = window.getComputedStyle(menuTerms[i]).paddingLeft;
		let left = menuTerms[i].getBoundingClientRect().left;
		let ulElem = document.querySelector('#' + menuTerms[i].id + ' > div > ul');
		let marginLeft = ulElem.getBoundingClientRect().left;
		if(marginLeft > 0) {
			menuPaddingLeft += ' - ' + marginLeft + 'px';
		}
		ulElem.style.paddingLeft = 'calc('+ left + 'px + ' + menuPaddingLeft + ')';
		// console.log('calc('+ left + 'px + ' + menuPaddingLeft + ')');
	}
}
addPaddingSubmenu();